import React, { useState } from "react";
import KiwiLogo from "../assets/images/Kiwi-Login.png";
import LoginPageImage from "../assets/images/Login-Page-Image.png";
import { FaArrowRightLong, FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LOGIN_API } from "../utils/constant";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({});
  const [auth, setAuth] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false); // Changed to false initially
  const navigate = useNavigate();

  const validateInput = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate email
    if (!auth.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(auth.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Validate password
    if (!auth.password) {
      newErrors.password = "Password is required.";
    } else if (auth.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate input fields
    const validationErrors = validateInput();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      return;
    }

    setLoading(true); // Set loading state to true
    try {
      const response = await fetch(LOGIN_API, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: auth.email,
          password: auth.password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || "Login failed. Please try again.");
        setLoading(false); // Reset loading state
        return;
      }

      const data = await response.json();

      // Store response in localStorage
      localStorage.setItem("authToken", JSON.stringify(data.token)); // Assuming `data.token` contains the authentication token
      localStorage.setItem("userData", JSON.stringify(data.user)); // Save user info if available

      toast.success("Login successful!");
      navigate("/dashboard", { state: { flag: true } }); // Redirect to dashboard or desired route
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="max-h-screen flex items-center justify-center p-4">
      <div className="bg-white w-full min-h-screen rounded-lg overflow-hidden grid grid-cols-1 lg:grid-cols-2 shadow-lg">
        {/* Left Section */}
        <div className="px-6 sm:px-10 py-8 flex flex-col justify-center">
          <div className="flex flex-col items-center lg:items-start mb-6">
            <img
              className="h-[100px] w-auto mb-4 lg:h-[150px]"
              src={KiwiLogo}
              alt="Kiwi Logo"
            />
            <p className="text-center lg:text-left text-gray-600 mb-2">
              Welcome Back !!!
            </p>
            <h1 className="font-bold text-2xl lg:text-3xl text-center lg:text-left mb-6">
              Login
            </h1>
          </div>

          <form
            onSubmit={handleSubmit}
            className="space-y-4 w-full max-w-md mx-auto lg:mx-0"
          >
            <div>
              <label className="block mb-2 text-sm font-medium">Email</label>
              <input
                onChange={(e) =>
                  setAuth((prevAuth) => ({
                    ...prevAuth,
                    email: e.target.value,
                  }))
                }
                autoFocus
                className="bg-[#C0DBEA] w-full h-[46px] px-3 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                type="text"
                placeholder="admin@gmail.com"
              />
              <p className="text-red-500 text-sm mt-1 h-5">
                {error.email || ""}
              </p>
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium">Password</label>
              <div className="relative">
                <input
                  onChange={(e) =>
                    setAuth((prevAuth) => ({
                      ...prevAuth,
                      password: e.target.value,
                    }))
                  }
                  className="bg-[#C0DBEA] w-full h-[46px] px-3 pr-10 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-3 top-1/2 -translate-y-1/2 text-green-800 flex items-center hover:text-green-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <p className="text-red-500 text-sm mt-1 h-5">
                {error.password || ""}
              </p>
            </div>

            <div className="text-right">
              <button
                type="button"
                onClick={() => navigate("/forgot-password")}
                className="text-sm text-[#3E782A] hover:underline"
              >
                Forgot Password
              </button>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`bg-[#3E782A] h-[46px] relative bottom-12 w-full lg:w-[150px] rounded-full text-white flex items-center justify-center gap-3 hover:bg-opacity-90 transition-colors duration-300 mx-auto lg:mx-0 ${
                loading && "opacity-50 cursor-not-allowed"
              }`}
            >
              {loading ? (
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-white"></div>
              ) : (
                <>
                  Login <FaArrowRightLong />
                </>
              )}
            </button>
          </form>
        </div>

        {/* Right Section */}
        <div className="bg-[#2F5B20] hidden lg:flex items-center justify-center">
          <img
            className="h-[80%] w-[80%] object-contain relative right-[330px]"
            src={LoginPageImage}
            alt="Login Page Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
