import React, { useState, useEffect, useRef } from "react";
import { X, Upload, Calendar, Users, Percent, Globe, Check } from "lucide-react";
import { LISTBRAND_API } from "../../utils/constant";
import { countryList } from "../../utils/countries";

const CustomDropdown = ({ value, onChange, options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-2.5 text-left bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 dark:focus:border-green-500"
      >
        <span className={`block truncate ${!value ? 'text-gray-500' : ''}`}>
          {options.find(option => option.value === value)?.label || placeholder}
        </span>
      </button>
      
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <button
              key={option.value}
              type="button"
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-700 ${
                value === option.value ? 'bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-400' : ''
              }`}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const MultiSelect = ({ values, onChange, options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredOptions = options.filter(option => 
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full min-h-[2.75rem] px-4 py-2 text-left bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
      >
        <div className="flex flex-wrap gap-1">
          {values.length === 0 && (
            <span className="text-gray-500">{placeholder}</span>
          )}
          {values.map((value) => (
            <span
              key={value}
              className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
            >
              {options.find(option => option.code === value)?.name}
            </span>
          ))}
        </div>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg">
          <div className="p-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700"
            />
          </div>
          <div className="max-h-60 overflow-auto">
            {filteredOptions.map((option) => (
              <button
                key={option.code}
                type="button"
                onClick={() => {
                  const newValues = values.includes(option.code)
                    ? values.filter((v) => v !== option.code)
                    : [...values, option.code];
                  onChange(newValues);
                }}
                className="w-full px-4 py-2 text-left flex items-center hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <span className={`w-4 h-4 mr-2 border rounded flex items-center justify-center ${
                  values.includes(option.code)
                    ? 'bg-green-500 border-green-500'
                    : 'border-gray-300 dark:border-gray-600'
                }`}>
                  {values.includes(option.code) && (
                    <Check className="w-3 h-3 text-white" />
                  )}
                </span>
                {option.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ImageUploader = ({ value, onChange, label }) => {
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="space-y-2">
      <label className="text-sm font-medium text-gray-700 dark:text-gray-200">
        {label}
      </label>
      <div className="flex items-center gap-4">
        <div className="relative group">
          {value ? (
            <div className="relative">
              <img 
                src={value} 
                alt="Preview" 
                className="h-24 w-24 object-cover rounded-lg border-2 border-gray-200 dark:border-gray-700 transition-all duration-200 group-hover:border-green-500"
              />
              <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-lg flex items-center justify-center">
                <Upload className="w-6 h-6 text-white" />
              </div>
            </div>
          ) : (
            <div className="h-24 w-24 flex items-center justify-center rounded-lg border-2 border-dashed border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800 transition-all duration-200 group-hover:border-green-500">
              <Upload className="w-8 h-8 text-gray-400 dark:text-gray-500" />
            </div>
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="block w-full text-sm text-gray-500 dark:text-gray-400 
            file:mr-4 file:py-2.5 file:px-4 file:rounded-full file:border-0 
            file:text-sm file:font-medium file:bg-green-50 dark:file:bg-green-900/30 
            file:text-green-600 dark:file:text-green-400 
            hover:file:bg-green-100 dark:hover:file:bg-green-900/40
            transition-all duration-200 cursor-pointer"
        />
      </div>
    </div>
  );
};

const CampaignModel = ({
  isOpen,
  onClose,
  campaign,
  setCampaign,
  onSubmit,
  isEditMode,
}) => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  // setCampaign({...campaign,availableCountries:[]})

  useEffect(() => {
    fetchBrands();
    // setCampaign({...campaign,availableCountries:[]})
    // if (campaign?.availableCountries) {
    //   setSelectedCountries(campaign.availableCountries);
    // }
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await fetch(LISTBRAND_API);
      const data = await response.json();
      setBrands(data.brands);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setCampaign(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleDateChange = (field, value) => {
    const date = new Date(value);
    handleInputChange(field, date);
  };

  const handleCountryChange = (value) => {
    setSelectedCountries(value);
    handleInputChange('availableCountries', value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit(campaign);
      onClose();
    } catch (error) {
      console.error("Error submitting campaign:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  const inputClassName = "w-full px-4 py-2.5 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-all duration-200";
  const labelClassName = "block text-sm font-medium text-gray-700 dark:text-gray-200";

  const statusOptions = [
    { value: 'draft', label: 'Draft' },
    { value: 'scheduled', label: 'Scheduled' },
    { value: 'active', label: 'Active' },
    { value: 'paused', label: 'Paused' },
    { value: 'completed', label: 'Completed' },
    { value: 'expired', label: 'Expired' }
  ];

  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center p-4 z-50 backdrop-blur-sm">
      <div className="bg-white dark:bg-gray-900 rounded-2xl w-full max-w-4xl max-h-[90vh] overflow-y-auto shadow-xl border border-gray-200/50 dark:border-gray-700/50 transition-all duration-200">
        <div className="p-8">
          <div className="flex justify-between items-center mb-8">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                {isEditMode ? 'Edit Scratch Card' : 'Create Scratch Card'}
              </h2>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Fill in the details below to {isEditMode ? 'update' : 'create'} your scratch card campaign
              </p>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className={labelClassName}>Title</label>
                  <input
                    type="text"
                    value={campaign.title || ''}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                    placeholder="Enter campaign title"
                    className={inputClassName}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className={labelClassName}>Subtitle</label>
                  <input
                    type="text"
                    value={campaign.subtitle || ''}
                    onChange={(e) => handleInputChange('subtitle', e.target.value)}
                    placeholder="Enter campaign subtitle"
                    className={inputClassName}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className={labelClassName}>Coupon Code</label>
                  <input
                    type="text"
                    value={campaign.couponCode || ''}
                    onChange={(e) => handleInputChange('couponCode', e.target.value)}
                    placeholder="Enter coupon code"
                    className={inputClassName}
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className={labelClassName}>Description</label>
                  <textarea
                    value={campaign.description || ''}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    placeholder="Enter campaign description"
                    className={`${inputClassName} resize-none`}
                    rows={4}
                    required
                  />
                </div>
              </div>

              <div className="space-y-6">
                <ImageUploader
                  value={campaign.graphic_main}
                  onChange={(value) => handleInputChange('graphic_main', value)}
                  label="Main Graphic"
                />

                <ImageUploader
                  value={campaign.graphic_secondary}
                  onChange={(value) => handleInputChange('graphic_secondary', value)}
                  label="Secondary Graphic"
                />

                <div className="space-y-2">
                  <label className={labelClassName}>Brand</label>
                  <CustomDropdown
                  value={campaign.brandId || ''}
                  onChange={(value) => handleInputChange('brandId', value)}
                  options={brands.map(brand => ({
                    value: brand._id,
                    label: brand.name
                  }))}
                  placeholder="Select Brand"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-2">
              <label className={labelClassName}>
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4" />
                  Display Date
                </div>
              </label>
              <input
                type="date"
                value={formatDateForInput(campaign.couponDisplayDate)}
                onChange={(e) => handleDateChange('couponDisplayDate', e.target.value)}
                className={inputClassName}
                required
              />
            </div>

            <div className="space-y-2">
              <label className={labelClassName}>
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4" />
                  Expiry Date
                </div>
              </label>
              <input
                type="date"
                value={formatDateForInput(campaign.couponExpiryDate)}
                onChange={(e) => handleDateChange('couponExpiryDate', e.target.value)}
                className={inputClassName}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="space-y-2">
              <label className={labelClassName}>
                <div className="flex items-center gap-2">
                  <Users className="w-4 h-4" />
                  Total Coupons
                </div>
              </label>
              <input
                type="number"
                value={campaign.totalCoupons || ''}
                onChange={(e) => handleInputChange('totalCoupons', parseInt(e.target.value))}
                min="1"
                className={inputClassName}
                required
              />
            </div>

            <div className="space-y-2">
              <label className={labelClassName}>
                <div className="flex items-center gap-2">
                  <Percent className="w-4 h-4" />
                  Win Percentage
                </div>
              </label>
              <input
                type="number"
                value={campaign.winPercentage || ''}
                onChange={(e) => handleInputChange('winPercentage', parseInt(e.target.value))}
                min="0"
                max="100"
                className={inputClassName}
                required
              />
            </div>

            <div className="space-y-2">
              <label className={labelClassName}>Status</label>
              <CustomDropdown
                value={campaign.status || 'draft'}
                onChange={(value) => handleInputChange('status', value)}
                options={statusOptions}
                placeholder="Select Status"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label className={labelClassName}>
              <div className="flex items-center gap-2">
                <Globe className="w-4 h-4" />
                Available Countries
              </div>
            </label>
            <MultiSelect
              values={selectedCountries}
              onChange={handleCountryChange}
              options={countryList}
              placeholder="Select countries..."
            />
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Click to select multiple countries
            </p>
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-800 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-6 py-2.5 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 flex items-center gap-2"
            >
              {loading ? (
                <>
                  <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span>Processing...</span>
                </>
              ) : (
                <span>{isEditMode ? 'Update Campaign' : 'Create Campaign'}</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);
};

export default CampaignModel;