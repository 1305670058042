// import React, { useEffect, useState, useCallback } from "react";
// import { FaEye } from "react-icons/fa";
// import { MdOutlineEdit } from "react-icons/md";
// import { EditModal} from "../components/model-&-table/BrandEditModal"
// import { RiDeleteBin6Fill } from "react-icons/ri";
// import {
//   ADDBRAND_API,
//   DELETEBRAND_API,
//   LISTBRAND_API,
//   UPDATEBRAND_API,
// } from "../utils/constant";
// import { AddUserModal } from "../components/model-&-table/BrandAddModal";
// import { ViewModal } from "../components/model-&-table/ViewBrandModal";
// import { toast } from "react-toastify";
// import LoadingSkeleton from "../components/LoadingSkeleton";

// const UserTable = () => {
//   const [isViewModalOpen, setIsViewModalOpen] = useState(false);
//   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//   const [isAddModalOpen, setIsAddModalOpen] = useState(false);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const [editFormData, setEditFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     logoUrl: "",
//   });

//   const [newUserData, setNewUserData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     logoUrl: "",
//   });

//   const [users, setUsers] = useState([]);

//   // Reset form states
//   const resetForms = () => {
//     setEditFormData({
//       name: "",
//       email: "",
//       password: "",
//       logoUrl: "",
//     });
//     setNewUserData({
//       name: "",
//       email: "",
//       password: "",
//       logoUrl: "",
//     });
//     setSelectedUser(null);
//   };

//   // Close all modals
//   const closeModals = () => {
//     setIsViewModalOpen(false);
//     setIsEditModalOpen(false);
//     setIsAddModalOpen(false);
//     resetForms();
//   };

//   // Handle escape key press
//   useEffect(() => {
//     const handleEscape = (event) => {
//       if (event.key === "Escape") {
//         closeModals();
//       }
//     };

//     document.addEventListener("keydown", handleEscape);
//     return () => document.removeEventListener("keydown", handleEscape);
//   }, []);

//   const fetchTable = async () => {
//     setIsLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(LISTBRAND_API, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Failed to fetch users");
//       }
//       const data = await response.json();
//       setUsers(data.brands);
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchTable();
//   }, []);

//   const handleView = useCallback((user) => {
//     setSelectedUser(user);
//     setIsViewModalOpen(true);
//   }, []);

//   const handleEdit = useCallback((user) => {
//     setSelectedUser(user);
//     setEditFormData({
//       name: user.name,
//       email: user.email,
//       password: "", // Don't pre-fill password
//       logoUrl: user.logoUrl,
//     });
//     setIsEditModalOpen(true);
//   }, []);

//   const handleEditSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${UPDATEBRAND_API}/${selectedUser._id}`, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           ...editFormData,
//           // Only include password if it was changed
//           password: editFormData.password ? editFormData.password : undefined,
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Failed to update user");
//       }
//       toast.success("Brand Updated Successfully")
//       await fetchTable();
//       closeModals();
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDelete = async (userId) => {
//     if (!window.confirm("Are you sure you want to delete this Brand?")) return;
//     toast.success("Brand Deleted Successfully")
//     setIsLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(`${DELETEBRAND_API}/${userId}`, {
//         method: "DELETE",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Failed to delete user");
//       }

//       await fetchTable();
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleAddNewUser = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     setError(null);
//     try {
//       const response = await fetch(ADDBRAND_API, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(newUserData),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Failed to add user");
//       }
//       toast.success("Brand Added Successfully")
//       await fetchTable();
//       closeModals();
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Modal Components

//   // Error alert component
//   const ErrorAlert = ({ message }) => (
//     <div
//       className="p-4 mb-4 bg-red-100 border border-red-400 text-red-700 rounded"
//       role="alert"
//     >
//       <p>{message}</p>
//     </div>
//   );

//   return (
//     <div className="w-full p-6 py-24 space-y-4 bg-white dark:bg-gray-900  shadow-lg h-screen">
//       {/* Header Section */}
//       <div className="flex items-center justify-between mb-6">
//         <h2 className="text-2xl font-bold dark:text-white text-gray-800">Brand Management</h2>
//         <button
//           onClick={() => setIsAddModalOpen(true)}
//           className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//         >
//           Add New Brand
//         </button>
//       </div>

//       {/* Error display */}
//       {error && <ErrorAlert message={error} />}

//       {/* Loading state */}
//       {/* {isLoading && (
//         <div className="flex justify-center items-center py-4">
//           <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
//         </div>
//       )} */}

//       {isLoading && (
//         <div className="relative bottom-24">
//           <LoadingSkeleton/>
//         </div>
//       )}

//       {/* Table Section */}
//       <div className="overflow-x-auto ">
//         <table className="min-w-full divide-y divide-gray-200">
//           <thead className="bg-gray-50 dark:bg-gray-500 bg-opacity-35 ">
//             <tr>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-500 dark:text-white uppercase"
//               >
//                 Logo
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 dark:text-white uppercase"
//               >
//                 Name
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 dark:text-white uppercase"
//               >
//                 Email
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 dark:text-white uppercase"
//               >
//                 Password
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 dark:text-white uppercase"
//               >
//                 Created On
//               </th>
//               <th
//                 scope="col"
//                 className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 dark:text-white uppercase"
//               >
//                 Actions
//               </th>
//             </tr>
//           </thead>
//           <tbody className="bg-white dark:bg-gray-800 bg-opacity-35 divide-y divide-gray-200">
//             {users.length === 0 ? (
//               <tr>
//                 <td colSpan="5" className="px-6 py-4 w-full text-center text-gray-500 dark:text-white">
//                   No users found
//                 </td>
//               </tr>
//             ) : (
//               users.map((user) => (
//                 <tr
//                   key={user._id}
//                   className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
//                 >
//                   <td className="px-6 py-4 whitespace-nowrap">
//                     <img
//                       src={user.logoUrl}
//                       alt={`${user.name}'s logo`}
//                       className="w-8 h-8 rounded-full mx-auto object-cover"
//                       onError={(e) => {
//                         e.target.src = "https://via.placeholder.com/32"; // Fallback image
//                       }}
//                     />
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap">
//                     <div className="font-medium text-gray-900 dark:text-white">{user.name}</div>
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-gray-500 dark:text-white">
//                     {user.email}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-gray-500 dark:text-white">
//                     {user.password}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-gray-500 dark:text-white">
//                     {new Date(user.createdAt).toLocaleDateString()}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
//                     <div className="flex justify-end gap-2">
//                       <button
//                         onClick={() => handleView(user)}
//                         className="text-indigo-600 hover:text-indigo-900 dark:hover:text-indigo-500"
//                         aria-label={`View ${user.name}'s details`}
//                       >
//                         <FaEye className=" text-green-600 text-xl" />
//                       </button>
//                       <button
//                         onClick={() => handleEdit(user)}
//                         className="text-indigo-600 hover:text-indigo-900"
//                         aria-label={`Edit ${user.name}'s details`}
//                       >
//                         <MdOutlineEdit className=" text-xl" />
//                       </button>
//                       <button
//                         onClick={() => handleDelete(user._id)}
//                         className="text-red-600 hover:text-red-900"
//                         aria-label={`Delete ${user.name}`}
//                       >
//                         <RiDeleteBin6Fill className="text-xl" />
//                       </button>
//                     </div>
//                   </td>
//                 </tr>
//               ))
//             )}
//           </tbody>
//         </table>
//       </div>

//       {/* Modals */}
//       {isViewModalOpen && (
//         <ViewModal selectedUser={selectedUser} closeModals={closeModals} />
//       )}
//       {isEditModalOpen && (
//         <EditModal
//           isLoading={isLoading}
//           editFormData={editFormData}
//           setEditFormData={setEditFormData}
//           closeModals={closeModals}
//           handleEditSubmit={handleEditSubmit}
//         />
//       )}

//       {isAddModalOpen && (
//         <AddUserModal
//           isLoading={isLoading}
//           setNewUserData={setNewUserData}
//           newUserData={newUserData}
//           closeModals={closeModals}
//           handleAddNewUser={handleAddNewUser}
//         />
//       )}
//     </div>
//   );
// };
// <>
// <AddUserModal/>
// <ViewModal/>
// <EditModal/>
// </>
// export default UserTable;

import React, { useEffect, useState } from "react";
import { Search, Plus, Edit2, Trash2, Eye, X, Loader2, Upload } from "lucide-react";
import { toast } from "react-toastify";
import {
  ADDBRAND_API,
  DELETEBRAND_API,
  LISTBRAND_API,
  UPDATEBRAND_API,
} from "../utils/constant";

const BrandManagement = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentModal, setCurrentModal] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    logoUrl: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  // Convert file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle file selection
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5242880) { // 5MB limit
        toast.error("File size should be less than 5MB");
        return;
      }
      
      try {
        setSelectedFile(file);
        const base64 = await convertToBase64(file);
        setFormData(prev => ({ ...prev, logoUrl: base64 }));
        setPreviewUrl(URL.createObjectURL(file));
      } catch (error) {
        toast.error("Error processing image");
        console.error("Error converting file to base64:", error);
      }
    }
  };

  // Fetch brands
  const fetchBrands = async () => {
    setLoading(true);
    try {
      const response = await fetch(LISTBRAND_API);
      const data = await response.json();
      setBrands(data.brands);
    } catch (error) {
      toast.error("Failed to fetch brands");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  // Cleanup preview URL when component unmounts or modal closes
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  // Filter brands based on search
  const filteredBrands = brands.filter(
    (brand) =>
      brand.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      brand.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Modal handlers
  const openModal = (type, brand = null) => {
    setCurrentModal(type);
    setSelectedBrand(brand);
    if (brand) {
      setFormData({
        name: brand.name,
        email: brand.email,
        logoUrl: brand.logoUrl || "",
      });
      setPreviewUrl(brand.logoUrl);
    } else {
      setFormData({ name: "", email: "", logoUrl: "" });
      setPreviewUrl(null);
    }
    setSelectedFile(null);
  };

  const closeModal = () => {
    setCurrentModal(null);
    setSelectedBrand(null);
    setFormData({ name: "", email: "", logoUrl: "" });
    setSelectedFile(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  };

  // Form handlers
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // CRUD operations
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = currentModal === "add" ? ADDBRAND_API : `${UPDATEBRAND_API}/${selectedBrand._id}`;
      const method = currentModal === "add" ? "POST" : "PUT";
      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (!response.ok) throw new Error("Operation failed");
      toast.success(`Brand ${currentModal === "add" ? "added" : "updated"} successfully`);
      await fetchBrands();
      closeModal();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (brandId) => {
    if (!window.confirm("Are you sure you want to delete this brand?")) return;
    setLoading(true);
    try {
      const response = await fetch(`${DELETEBRAND_API}/${brandId}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete brand");
      toast.success("Brand deleted successfully");
      await fetchBrands();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Brand Management</h1>
        <button
          onClick={() => openModal("add")}
          className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus size={20} />
          Add Brand
        </button>
      </div>

      {/* Search Bar */}
      <div className="relative mb-6">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Search brands..."
          className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Brands Table */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Logo</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Created At</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {loading ? (
                <tr>
                  <td colSpan="5" className="px-6 py-4 text-center">
                    <Loader2 className="h-6 w-6 animate-spin mx-auto" />
                  </td>
                </tr>
              ) : filteredBrands.length === 0 ? (
                <tr>
                  <td colSpan="5" className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
                    No brands found
                  </td>
                </tr>
              ) : (
                filteredBrands.map((brand) => (
                  <tr key={brand._id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <img
                        src={brand.logoUrl || "https://via.placeholder.com/40"}
                        alt={`${brand.name} logo`}
                        className="h-10 w-10 rounded-full object-cover"
                        onError={(e) => {
                          e.target.src = "https://via.placeholder.com/40";
                        }}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                      {brand.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                      {brand.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                      {new Date(brand.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end gap-3">
                        <button
                          onClick={() => openModal("view", brand)}
                          className="text-blue-600 hover:text-blue-900 dark:hover:text-blue-400"
                        >
                          <Eye size={18} />
                        </button>
                        <button
                          onClick={() => openModal("edit", brand)}
                          className="text-yellow-600 hover:text-yellow-900 dark:hover:text-yellow-400"
                        >
                          <Edit2 size={18} />
                        </button>
                        <button
                          onClick={() => handleDelete(brand._id)}
                          className="text-red-600 hover:text-red-900 dark:hover:text-red-400"
                        >
                          <Trash2 size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {currentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                {currentModal === "add" ? "Add New Brand" : currentModal === "edit" ? "Edit Brand" : "View Brand"}
              </h2>
              <button onClick={closeModal} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
                <X size={20} />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700"
                    disabled={currentModal === "view"}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700"
                    disabled={currentModal === "view"}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Logo
                  </label>
                  {currentModal !== "view" && (
                    <div className="mt-1 flex items-center">
                      <label className="relative cursor-pointer bg-white dark:bg-gray-700 rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                        <span className="inline-flex items-center px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <Upload className="h-4 w-4 mr-2" />
                          Upload Logo
                        </span>
                        <input
                          type="file"
                          className="sr-only"
                          accept="image/*"
                          onChange={handleFileChange}
                          disabled={currentModal === "view"}
                        />
                      </label>
                    </div>
                  )}
                  {(previewUrl || formData.logoUrl) && (
                    <div className="mt-2">
                      <img
                        src={previewUrl || formData.logoUrl}
                        alt="Logo preview"
                        className="h-20 w-20 object-cover rounded-lg"
                      />
                    </div>
                  )}
                </div>
                {currentModal !== "view" && (
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-400"
                  >
                    {loading ? (
                      <Loader2 className="h-5 w-5 animate-spin mx-auto" />
                    ) : currentModal === "add" ? (
                      "Add Brand"
                    ) : (
                      "Update Brand"
                    )}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandManagement;